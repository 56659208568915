if (avastGlobals.web.locale == 'en-us' ||
	avastGlobals.web.locale == 'en-gb' ||
	avastGlobals.web.locale == 'en-ca' ||
	avastGlobals.web.locale == 'en-au' ||
	avastGlobals.web.locale == 'fr-fr' ||
	avastGlobals.web.locale == 'fr-ch' ||
	avastGlobals.web.locale == 'fr-ca' ||
	avastGlobals.web.locale == 'de-de' ||
	avastGlobals.web.locale == 'de-ch') {


	var medias = tns({
		"container": ".slider-medias",
		"mode": "gallery",
		"controlsContainer": ".slider-medias-controls",
		"navContainer": ".slider-medias-navs",
		"controlsPosition": "bottom",
		"navPosition": "bottom",
		"slideBy": "page",
		"autoplay": true,
		"autoplayButtonOutput": false,
		onInit: function() {
			stopAutoplay("slider-medias");
		}
	});


	// <#cmp.carousel#> – Slider with Media Section
	// Stop autoplay while hovering above controls and navs and when these are clicked on
	function stopAutoplay(name) {
		var
			sliderEl = document.querySelector('[data-name='+name+']'),
			controls = sliderEl.querySelector(".packed-control"),
			pause = function() { medias.pause(); },
			play = function() { medias.play(); }

		controls.addEventListener('mouseenter', pause);
		controls.addEventListener('mouseleave', play);
		controls.addEventListener('click', function() {
			pause;
			controls.removeEventListener('mouseenter', pause);
			controls.removeEventListener('mouseleave', play);
		});
	};

	// <#cmp.carousel#> – Create a slide counter bellow the Carousel
	avm.require('avast.web.slideCounter')(medias);
}


//
//	GLOWEB-8096 – "Live" counter of threats stopped A/B test
//	---------------------------------------------------------
//	👉 TODO: overal clean up
//	👉 TODO: refactoring
//
//	. . . . . . . . . . . . . .
//
$(document).ready(function(){

	//
	//	Check if the stripe with Graphs exists… 
	//
	if ( document.querySelector('.cmp-counter__section') ) {
		/**
		 *
		 * ApexCharts – version 3.45.2
		 * 
		 * DOCS: https://apexcharts.com/docs/
		 * 
		 * * * * * * * * *
		 *
		 * Following code is a composition of various code:
		 * https://codepen.io/collection/vBbzzL
		 * 
		 * * * * * * * * *
		 * 
		 * HOW TO… Update Charts from JSON API & AJAX ––– If this A/B test (GLOWEB-8096) will be success, we will need to implement this:
		 * https://apexcharts.com/docs/update-charts-from-json-api-ajax/
		 * 
		 * * * * * * * * *
		 * @version 0.1.0
		 * @author Frankie Rapcak <frantisek.rapcak@gendigital.com>, Risto Stojkovski <risto.stojkoski@gendigital.com>
		 * @see {@link https://apexcharts.com/}
		 * 
		 * @param {number} graphAnimationSpeed – how fast should be the Chart updated? Value in miliseconds
		 * @param {number} numberOfPointsOnTheGraphLine - number of points rendered on the graph line
		 * @param {number} numberOfAttacksPerSecondMinimum - minimal value on the graph line
		 * @param {number} numberOfAttacksPerSecondMaximum - maximal value on the graph line
		 * 
		 */


		//
		//	S E T T I N G S
		//
		var
			formattedNumberLocale = 'en-US'	// customize the number formatting. Setting this to "en-US" will add thousend separator - the "," character
			graphAnimationSpeed = 1500,		// value in miliseconds; when set to 1000 - the animation is "choppy" (CZ: "trhaná" animace); 2000 – value defined in Figma
			numberOfPointsOnXaxis = 9,		// the number of points shown in the graph line
			numberOfPointsOnTheGraphLine = 99,		// to create smooth animation, we need much more points then 9
			numberOfAttacksPerSecondMinimum = 50,	// 50 – value defined in Figma
			numberOfAttacksPerSecondMaximum = 179,	// 179 – value defined in Figma
			numberOfAttacksPerSecondElementID = 'js-attacks-per-second',
			numberOfAttacksPerSecondElement = document.getElementById(numberOfAttacksPerSecondElementID),
			numberOfAttacksPerSecondElementInitialValue = numberOfAttacksPerSecondElement.getAttribute('data-min-value');
			numberOfAttacksPerHourElementID = 'js-attacks-per-hour',
			numberOfAttacksPerDayElementID = 'js-attacks-per-day',
			randomNumber = 0,				// generated random number will be stored here
			// A minimum of 11 items in the array is required for the graph to be rendered at 100% tile width.
			attacksPerHourData = [110, 84, 162, 87, 114, 165, 73, 72, 143, 110, 63],
			attacksPerDayData = [90, 106, 121, 58, 93, 62, 145, 145, 153, 96, 118],
			graphColorTheme = '#FF7800',	// $orange-bright – Avast Primary Brand Color
			spinnerFadeOutSpeed = 444,	// 🟥 A C H T U N G 🟥  this has to be the SAME NUMBER as used in index.scss (.cmp-counter____spinner – transition speed)
			tileVisibilityDelay = graphAnimationSpeed,	// Specifies the duration required for the initial rendering of the graph.
			tilesNodeList = document.querySelectorAll('.cmp-counter'),
			secondsTile = document.getElementById('cmp-counter--seconds'),
			hoursTile = document.getElementById('cmp-counter--hours'),
			daysTile = document.getElementById('cmp-counter--days'),
			worldwideTile = document.getElementById('cmp-counter--worldwide'),
			graphHeight = 100,
			//
			//	⚠️ when you use breakpoint.sm the width will be applied from SM breakpoint UP, but it's the opposite, its DOWN.
			//
			breakpoint = {
				"xs":	320,	// resolution 319px and down
				"sm":	576,	// resolution 575px and down
				"md":	768,	// resolution 767px and down
				"lg":	992,	// resolution 991px and down
				"xl":	1280,	// resolution 1279px and down
				"xl2":	1366,	// resolution 1365px and down
				"xxl":	1600,	// resolution 1599px and down
				"xxxl":	9999	// resolution 9999px and down. The 9999px is overkill. But we are ready for 8K resolution
			},
			//
			// Graph width has to be slightly higher than the parent's (the Tile) width
			//
			graphWidth = {
				"xs":	348,
				"sm":	362,
				"md":	278,
				"lg":	368,
				"xl":	370,
				"xl2":	316,
				"xxl":	340,
				"xxxl":	360
			},
			//
			//	Graph width on various breakpoints
			//
			graphResponsiveness = [
				{ breakpoint: breakpoint.xs, options: { chart: { width: graphWidth.xs } } },	// @media-breakpoint-up(xs)
				{ breakpoint: breakpoint.sm, options: { chart: { width: graphWidth.sm } } },	// @media-breakpoint-up(sm)
				{ breakpoint: breakpoint.md, options: { chart: { width: graphWidth.md } } },	// @media-breakpoint-up(md)
				{ breakpoint: breakpoint.lg, options: { chart: { width: graphWidth.lg } } },	// @media-breakpoint-up(lg)
				{ breakpoint: breakpoint.xl, options: { chart: { width: graphWidth.xl } } },	// @media-breakpoint-up(xl)
				{ breakpoint: breakpoint.xl2, options: { chart: { width: graphWidth.xl2 } } },	// @media-breakpoint-up(xl2)
				{ breakpoint: breakpoint.xxl, options: { chart: { width: graphWidth.xxl } } },	// @media-breakpoint-up(xxl)
				{ breakpoint: breakpoint.xxxl, options: { chart: { width: graphWidth.xxxl } } }	// @media-breakpoint-up(xxxl)
			],
			//
			//	Tiles fade-in speed
			//
			secondsTileVisibilityDelay = tileVisibilityDelay,			// ensure the graph is shown fully rendered (there is an ugly design glitch on initial graph animation: The graph line animates faster then the gradient)
			hoursTileVisibilityDelay = tileVisibilityDelay + 500,		// value in miliseconds - default 1500 -> 500 is only for GLOWEB-8703
			daysTileVisibilityDelay = tileVisibilityDelay + 500,		// value in miliseconds - default 2500 -> 500 is only for GLOWEB-8703
			worldwideTileVisibilityDelay = tileVisibilityDelay + 4000,	// value in miliseconds
			//
			//	Count up – config:
			//
			// attacksPerHourCountUpStart = 643000, Original value
			// attacksPerHourCountUpEnd = 643836, Original value
			attacksPerHourCountUpStart = 235, // GLOWEB-8703
			attacksPerHourCountUpEnd = 235, // GLOWEB-8703
			attacksPerHourCountUpDuration = 0.3,	// value in seconds
			// attacksPerDayCountUpStart = 15450000, Original value
			// attacksPerDayCountUpEnd = 15452054, Original value
			attacksPerDayCountUpStart = 6849315,// GLOWEB-8703
			attacksPerDayCountUpEnd = 6849315,// GLOWEB-8703
			attacksPerDayCountUpDuration = 0.2;	// value in seconds




	// 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴
	//
	// - - - - - - - - - - -	🎬 START ––– S E C O N D S

		//
		//	Attacks per SECONDS – Chart configuration
		//
		var attacksPerSecondChartConfig = {
			chart: {
				width: graphWidth.xs,
				height: graphHeight,
				type: "area",
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
					easing: "linear",
					dynamicAnimation: {
						enabled: true,
						speed: graphAnimationSpeed
					}
				}
			},
			responsive: graphResponsiveness,
			series: [{
				data: [],	// this array will be filled later by randomised number
				color: graphColorTheme	// this will RECOLOR the graph to Avast Brand colors
			}],
			grid: {
				show: false // Hide the grid lines on the chart
			},
			dataLabels: {
				enabled: false
			},
			tooltip: {
				enabled: false // Disable tooltip labels shown on :hover
			},
			stroke: {
				width: 3,	// thickness of the graph line (= border-width)
				curve: "smooth"
			},
			xaxis: {
				range: numberOfPointsOnXaxis,
				lines: {
					show: false // disable grid lines 	// ❌ NOT WORKING?
				},
				labels: {
					show: false // Disable text labels
				}
			},
			yaxis: {
				//max: numberOfAttacksPerSecondMaximum,	// ❓ WHAT does this will do??? Will it affect the Chart height?
				lines: {
					show: false
				},
				labels: {
					show: false // Disable text labels
				}
			}
		};

		//
		//	Create a NEW chart instance – number of attacks per S E C O N D
		//
		var attacksPerSecondChart = new ApexCharts(document.querySelector("#cmp-counter__graph--seconds"), attacksPerSecondChartConfig);
		attacksPerSecondChart.render();

		//
		//	Generate random numbers from the defined range
		//
		function getRandomNumber(min, max) {
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}

		//
		// Create an array of X random numbers from the defined range (numberOfAttacksPerSecondMinimum, numberOfAttacksPerSecondMaximum)
		//
		var randomData = [];
		for (var i = 0; i < numberOfPointsOnTheGraphLine; i++) {
			randomData.push(getRandomNumber(numberOfAttacksPerSecondMinimum, numberOfAttacksPerSecondMaximum));
		}

		//
		// Attacks per SECOND – Graph – initial state – feed the chart with initial data
		//
		attacksPerSecondChart.updateSeries([{
			data: randomData
		}]);

		//
		//	Initialize variables for the Count up
		//
		var
			randomDataPreviousValue = randomData[randomData.length - 1],	// get last array item
			runCountThisTime = true;	// count up will trigger every odd run

		//
		//	Update the graph every second with randomised numbers
		//
		var timer = setInterval(function () {
			//	Generate new random number
			randomNumber = getRandomNumber(numberOfAttacksPerSecondMinimum, numberOfAttacksPerSecondMaximum);

			// Add the new random number to the end of the array
			randomData.push(randomNumber);

			// Update the chart's series with the updated random data
			attacksPerSecondChart.updateSeries([{
				data: randomData
			}]);

			//
			//	Update the text value
			//
			// 👉 TODO: clean up

			// Update the text value if runCountThisTime is true
			if (runCountThisTime) {
				var options = {
						startVal: randomDataPreviousValue,
						duration: 1.5	// value in seconds
					};

				var demoSecond = new countUp.CountUp(numberOfAttacksPerSecondElementID, randomNumber, options);
				if (!demoSecond.error) { demoSecond.start(); }
				else { console.error(demoSecond.error); }

				// Toggle runCountThisTime flag for the next run
				runCountThisTime = false;
			}
			else {
				// Toggle runCountThisTime flag for the next run
				runCountThisTime = true;
			}

			// Update previous value for the next iteration
			randomDataPreviousValue = randomNumber;

		}, graphAnimationSpeed);	// End of __ var timer


	// - - - - - - - - - - -	🎬 END ––– S E C O N D S
	//
	// 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴 🔴





	// 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠
	//
	// - - - - - - - - - - -	🎬 START ––– H O U R

		//
		//	Attacks per HOUR – Chart configuration
		//
		var attacksPerHourChartConfig = {
			chart: {
				width: graphWidth.xs,
				height: graphHeight,
				type: "area",
				toolbar: {
					show: false
				},
				animations: {
					enabled: false
				}
			},
			responsive: graphResponsiveness,
			series: [{
				data: attacksPerHourData,
				color: graphColorTheme
			}],
			grid: {
				show: false // Hide the grid lines on the chart
			},
			dataLabels: {
				enabled: false
			},
			tooltip: {
				enabled: false
			},
			stroke: {
				width: 3,
				curve: "smooth"
			},
			xaxis: {
				range: numberOfPointsOnXaxis,
				lines: {
					show: false
				},
				labels: {
					show: false // Disable text labels
				}
			},
			yaxis: {
				lines: {
					show: false
				},
				labels: {
					show: false // Disable text labels
				}
			}
		};

		//
		//	Create a NEW chart instance – number of attacks per H O U R
		//
		var attacksPerHourChart = new ApexCharts(document.querySelector("#cmp-counter__graph--hours"), attacksPerHourChartConfig);
		attacksPerHourChart.render();

	// - - - - - - - - - - -	🎬 END ––– H O U R
	//
	// 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠





	// 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵
	//
	// - - - - - - - - - - -	🎬 START ––– D A Y

		//
		//	Attacks per DAY – Chart configuration
		//
		var attacksPerDayChartConfig = {
			chart: {
				width: graphWidth.sm,
				height: graphHeight,
				type: "area",
				toolbar: {
					show: false
				},
				animations: {
					enabled: false
				}
			},
			responsive: graphResponsiveness,
			series: [{
				data: attacksPerDayData,
				color: graphColorTheme
			}],
			grid: {
				show: false // Hide the grid lines on the chart
			},
			dataLabels: {
				enabled: false
			},
			tooltip: {
				enabled: false
			},
			stroke: {
				width: 3,
				curve: "smooth"
			},
			xaxis: {
				range: numberOfPointsOnXaxis,
				lines: {
					show: false
				},
				labels: {
					show: false // Disable text labels
				}
			},
			yaxis: {
				lines: {
					show: false
				},
				labels: {
					show: false // Disable text labels
				}
			}
		};

		//
		//	Create a NEW chart instance – number of attacks per D A Y
		//
		var attacksPerDayChart = new ApexCharts(document.querySelector("#cmp-counter__graph--days"), attacksPerDayChartConfig);
		attacksPerDayChart.render();

	// - - - - - - - - - - -	🎬 END ––– D A Y
	//
	// 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵 🔵


		//
		//	Count-up the number of attacks
		//
		function animateCountUp(elem, start, end, countUpDuration) {
			var elementId = elem.getAttribute('id');

			// TODO 👉 REFACTORING
			if (elementId === numberOfAttacksPerHourElementID){
				var options = {
					startVal: start,
					duration: countUpDuration
				};

				var demoHour = new countUp.CountUp(elementId, end, options);

				if (!demoHour.error) {
					demoHour.start();
				} else {
					console.error(demoHour.error);
				}
			} else if (elementId === numberOfAttacksPerDayElementID){
				var options = {
					startVal: start,
					duration: countUpDuration
				};

				var demoDay = new countUp.CountUp(elementId, end, options);

				if (!demoDay.error) {
					demoDay.start();
				} else {
					console.error(demoDay.error);
				}
			}
		}

		//
		//	Fade-out the the Spinner & show the Tile content
		//
		function showTile(tile, delay, countUpConfig) {
			setTimeout(function () {
				if(tile) {
					tile.classList.add('js-show-content');
					// Only for GLOWEB-8703 - if/else statement (original is whats inside else)
					if(tile.id=="cmp-counter--hours") {
						document.getElementById('js-attacks-per-hour').innerText = document.getElementById('js-attacks-per-hour').dataset.endValue;
					}
					else {

						//
						//	Start count-up (Hour tile, Days tile)
						//
						if (countUpConfig) {
							animateCountUp(document.getElementById(countUpConfig.id), countUpConfig.start, countUpConfig.end, countUpConfig.duration);
						}

					}
					//
					//	Spinner fade-out is completed => add helper class to fix the z-index problem (causing Tooltip not shown on :hover)
					//
					setTimeout(function () {
						tile.classList.add('js-animation-completed');
					}, spinnerFadeOutSpeed);
				}
			}, delay);
		}


		//
		//	Fade in the Tiles, one by one
		//
		showTile(secondsTile, secondsTileVisibilityDelay);
		showTile(hoursTile, hoursTileVisibilityDelay, { id: numberOfAttacksPerHourElementID, start: attacksPerHourCountUpStart, end: attacksPerHourCountUpEnd, duration: attacksPerHourCountUpDuration });
		showTile(daysTile, daysTileVisibilityDelay, { id: numberOfAttacksPerDayElementID, start: attacksPerDayCountUpStart, end: attacksPerDayCountUpEnd, duration: attacksPerDayCountUpDuration });
		showTile(worldwideTile, worldwideTileVisibilityDelay);

	}	// END of if ( document.querySelector('.cmp-counter__section') )

});
// END of the GLOWEB-8096 – "Live" counter of threats stopped A/B test
//	-------------------------------------------------------------------




//
//	Jumpshot – Banner –– GLOWEB-8731
//
$(document).ready(function(){

	// Get DOM reference to the banner
	var jumpshotBanner = document.querySelector('.jumpshot-banner');

	// Don't show the Banner to visitors, which already interacted with it (by clicking the "here" link or the "close" button)
	if (avastGlobals.web.locale == 'en-us' && jumpshotBanner !== null && localStorage.getItem('visitorInteractedWithJumpshotBanner') === null) {

		// Show the banner
		jumpshotBanner.style.display = 'block';

		// Check if Performance cookies are enabled
		function performanceCookiesEnabled() {
			var
				activeGroups = window.OptanonActiveGroups,
				performanceCookiesGroupID = 'C0002';	// Optanon code for Performance cookies

			// Ensure activeGroups is defined and is a string
			if (typeof activeGroups === 'string') {
				// Check if the groupID is present in the array
				return activeGroups.split(',').includes(performanceCookiesGroupID);
			}
			return false;
		}

		//	Banner –– Link
		jumpshotBanner.querySelector('a').addEventListener('click', function(e){
			// Set indicator of visitor's interaction with the banner
			localStorage.setItem('visitorInteractedWithJumpshotBanner', 'true');

			// Track the visitor only if Performance Cookies are enabled
			if (performanceCookiesEnabled()) {
				sdl.push({
					user: {
						click: {
							element: {
								actionType: 'click', // 👉 TODO: Verify with @OndrejSubrt
								component: 'link', // 👉 TODO: Verify with @OndrejSubrt
								path: '/' + sdlObj.screen.locale + '/link', // 👉 TODO: Verify with @OndrejSubrt
								id: 'jumpshot-banner' // 👉 TODO: Verify with @OndrejSubrt
							}
						}
					},
					event: 'user.click.element'
				});
			}
		});

		//	Banner –– Close button
		jumpshotBanner.addEventListener('closed.bs.alert', function (e) {
			// Set indicator of visitor's interaction with the banner
			localStorage.setItem('visitorInteractedWithJumpshotBanner', 'true');

			// Track the visitor only if Performance Cookies are enabled
			if (performanceCookiesEnabled()) {
				sdl.push({
					user: {
						click: {
							element: {
								actionType: 'close',
								component: 'cmp-alert', // 👉 TODO: Verify with @OndrejSubrt
								path: '/' + sdlObj.screen.locale + '/cmp-alert', // 👉 TODO: Verify with @OndrejSubrt
								id: 'jumpshot-banner' // 👉 TODO: Verify with @OndrejSubrt
							}
						}
					},
					event: 'user.click.element'
				});
			}
		});
	}
});
